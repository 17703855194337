import React, { Component } from 'react';
import axios from 'axios';

import Box from '@mui/joy/Box';

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BACK } from './constant';

class Friends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            friendsIds: []
        };
    }

    componentDidMount() {
        axios.get(`${BACK}/api/friends/display`)
            .then(response => {
                this.setState({ friendsIds: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { friendsIds } = this.state
        return (
            <div className='friends-center' >
                <div className='friends-title' >Friends</div>
                
                <div className='isPhone'>(Swipe left to see more) </div>
                <Box className='media-friend'
                    sx={{
                        display: 'flex',
                        gap: 1,
                        py: 0,
                        overflow: 'auto',
                        scrollSnapType: 'x mandatory',
                        '& > *': {
                            scrollSnapAlign: 'center',
                        },
                        '::-webkit-scrollbar': { display: 'flex' },
                    }}
                >
                    {friendsIds.map((friendId) => (
                        <Card
                            orientation="horizontal"
                            key={`${friendId.name}`}
                            sx={{
                                '--Card-padding': (theme) => theme.spacing(1),
                            }}
                        >
                            <AspectRatio sx={{ minWidth: 200 }} >
                                <img
                                    src={`https://lh3.googleusercontent.com/d/${friendId.logo}`}
                                    alt={`friendId.name`}
                                    style={{ objectFit: 'contain', width: '150px', height: '100px' }}
                                    onClick={() => {
                                        window.open(`${friendId.site}`, '_blank')
                                    }}
                                />
                            </AspectRatio>
                        </Card>
                    ))}
                </Box>
                <div className="isDesktop" style={{ position: 'center', top: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'space-around' }}>
                        <KeyboardArrowLeftIcon
                            onClick={() => {
                                const container = document.querySelector('.media-friend');
                                container.scrollBy({
                                    left: -container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                        <div style={{ position: "center", color: "white", fontFamily: 'Norton' }} > To browse left/right use the arrows </div>
                        <KeyboardArrowRightIcon
                            onClick={() => {
                                const container = document.querySelector('.media-friend');
                                container.scrollBy({
                                    left: container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                    </div>
            </div>
        );
    }
}

export default Friends;

//  onClick={()=>{window.open(imageId.site, '_blank')}}