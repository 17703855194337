import React, { Component } from 'react'

import moment from 'moment'
import axios from 'axios'


import { IconButton } from '@material-ui/core'

import { DatePicker, message } from 'antd'

import { ReactComponent as YouTubeIcon } from '../Assets/youtube.svg'
import { ReactComponent as FacebookIcon } from '../Assets/facebook.svg'
import { ReactComponent as InstagramIcon } from '../Assets/instagram.svg'
import { ReactComponent as TikTokIcon } from '../Assets/tiktok.svg'
import { ReactComponent as MixcloudIcon } from '../Assets/mixcloud.svg'

import { ReactComponent as WhatsAppIcon } from '../Assets/whatsapp.svg'

import { BACK } from './constant';

class BookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      phone: '',
      location: '',
      subject: '',
      date: '',
      message: ''
    }
  }

  handleInputChange(field) {
    return function (event) {
      this.setState({ [field]: event.target.value })
    }
  }

  handleDateChange = (date, dateString) => {

    const formattedDate = moment(dateString).format('DD-MM-YYYY');
    this.setState({
      date: formattedDate
    });
  }

  handleBookNow = event => {
    event.preventDefault()
    const errors = this.validateForm();
    if (Object.keys(errors).length === 0) {
      let data = {
        fullName: this.state.fullName,
        email: this.state.email,
        phone: this.state.phone,
        location: this.state.location,
        subject: this.state.subject,
        date: this.state.date,
        message: this.state.message
      }
      axios.post(`${BACK}/api/contact`, data)
        .then((response) => {
          message.success("Thank-you for your message!", [10])
          this.resetForm()
        })
        .catch((error) => {
          message.error('Please fill all fields! :)')
        });
    } else {
      if (errors.fullName) {
        message.error(errors.fullName, [10]);
      }
      if (errors.email) {
        message.error(errors.email, [10]);
      }
      if (errors.phone) {
        message.error(errors.phone, [10]);
      }
      if (errors.location) {
        message.error(errors.location, [10]);
      }
      if (errors.subject) {
        message.error(errors.subject, [10]);
      }
      if (errors.message) {
        message.error(errors.message, [10]);
      }
    }
  }
  resetForm = () => {
    this.setState({
      fullName: '',
      email: '',
      phone: '',
      location: '',
      subject: '',
      date: '',
      message: ''
    })
  }
  validateForm = () => {
    let errors = {};
    if (this.state.fullName.trim() === '') {
      errors.fullName = 'Full Name is required';
    }
    if (this.state.email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = 'Email address is invalid';
    }
    if (this.state.phone.trim() === '') {
      errors.phone = 'Phone is required';
    }
    if (this.state.location.trim() === '') {
      errors.location = 'Location is required';
    }
    if (this.state.subject.trim() === '') {
      errors.subject = 'Subject is required';
    }
    if (this.state.message.trim() === '') {
      errors.message = 'Message is required';
    }
    return errors;
  };

  handleOpenInstagram = () => {
    window.open("https://www.instagram.com/liviustoicadj/")
  };
  handleOpenFacebook = () => {
    window.open("https://www.facebook.com/liviustoicadj");
  };
  handleOpenYoutube = () => {
    window.open("https://www.youtube.com/@DeBunSimtTV")
  };
  handleOpenTiktok = () => {
    window.open("https://www.tiktok.com/@liviustoicadj")
  };
  handleOpenMixcloud = () => {
    window.open("https://www.mixcloud.com/liviu-stoica-dj/")
  };
  handleWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=40744558784")
  }

  handlePhoneCall = () => {
    window.location.href = 'tel:+40744 558 784'
  }
  render() {
    const options = [
      { value: 'Dj', label: 'Dj' },
      { value: 'MC', label: 'MC' },
      { value: 'Petrecere Corporate', label: 'Petrecere Corporate' },
      { value: 'MC Prezentare Eveniment', label: 'MC Prezentare Eveniment' },
      { value: 'Dj Nunta', label: 'Dj Nunta' },
      { value: 'Dj Botez', label: 'Dj Botez' },
      { value: 'Other', label: 'Other' }
    ];
    return (
      <>
        <div className='book-center'>
          <div className='book-title-1 notPhone' >Book Now</div>
        </div>
        <div className='book-now'>
          <div className='column-book'>
            <div className='find-me'>
              <div className='book-title-2'> Linktree </div>
              <div className='icon-view'>
                <IconButton onClick={this.handleOpenInstagram}> <InstagramIcon className='icon-contact' /> </IconButton>
                <IconButton onClick={this.handleOpenFacebook}> <FacebookIcon className='icon-contact' /> </IconButton>
                <IconButton onClick={this.handleOpenTiktok}> <TikTokIcon className='icon-contact' /> </IconButton>
                <IconButton onClick={this.handleOpenYoutube}> <YouTubeIcon className='icon-contact' /> </IconButton>
                <IconButton onClick={this.handleOpenMixcloud}> <MixcloudIcon className='icon-contact' /> </IconButton>

              </div>
              <button onClick={this.handleWhatsApp} className='fast-contact-button' >
                <div className='fast-contact'>
                  <WhatsAppIcon className='fast-contact-icon' />
                  <div className='fast-contact-text' >Contact Rapid</div>
                </div>
              </button>
              <div>
                <button className='contact-number' onClick={this.handlePhoneCall} >0744 558 784</button>
              </div>
              <div className='gdpr notPhone'>Datele personale pe care le colectam prin intermediul formularelor aflate pe site sunt folosite numai in scopul de a va contacta sau pentru a va trimite ofertele solicitate in detaliu.</div>
            </div>
          </div>
          <div style={{marginTop:'5vh'}} className='column-book IsPhone'>
            <div >
              <div className='book-title-1 ' >Book Now</div>
            <div className='gdpr' >Datele personale pe care le colectam prin intermediul formularelor aflate pe site sunt folosite numai in scopul de a va contacta sau pentru a va trimite ofertele solicitate in detaliu.</div>
         </div> </div>
          <div className='column-book'>
            <div className='contact-form'>
              <input
                type="text"
                className='input-size'
                required
                placeholder='Nume Prenume'
                value={this.state.fullName}
                onChange={this.handleInputChange('fullName').bind(this)}
              />
              <input
                type="email"
                className='input-size'
                placeholder='Email'
                required
                value={this.state.email}
                onChange={this.handleInputChange('email').bind(this)}
              />
              <input
                type="tel"
                className='input-size'
                placeholder='Telefon'
                required
                value={this.state.phone}
                onChange={this.handleInputChange('phone').bind(this)}
              />
              <select id="serviciu-dorit" className='select-serv' value={this.state.subject} onChange={this.handleInputChange('subject').bind(this)}>
                <option value=""  >Serviciu Dorit</option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
              <input
                type="text"
                className='input-size'
                placeholder='Locatie'
                required
                value={this.state.location}
                onChange={this.handleInputChange('location').bind(this)}
              />
              <DatePicker placeholder='Data' className='input-data' onChange={this.handleDateChange} />
              <textarea
                rows="5"
                cols="30"
                type="text"
                className='input-mesaj'
                placeholder='Mesaj'
                required
                value={this.state.message}
                onChange={this.handleInputChange('message').bind(this)}
              />
              <button className='book-button' onClick={this.handleBookNow} >Trimite solicitare</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default BookNow