import React, { Component } from 'react'
import PastEvents from './past-events-gallery'
import PhotoGallery from './photo-gallery'
import VideoGallery from './video-gallery'

class Gallery extends Component {
    render() {
        return (
            <>
                <div className='flex-component space'>
                        <VideoGallery />
                        <br/>
                        <PhotoGallery />
                        <br/>
                        <PastEvents/>
                </div>
            </>
        )
    }
}

export default Gallery