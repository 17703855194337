import React, { Component } from 'react'


// https://drive.google.com/file/d/1aJz2QlFMdBGSDeI1uXBs30UnO-sXHpF5/view?usp=sharing // Background image
// https://lh3.googleusercontent.com/d/1aJz2QlFMdBGSDeI1uXBs30UnO-sXHpF5
// https://drive.google.com/file/d/1c864cAyKVXBhKCmAGiYBnqp3D5ainz6d/view?usp=sharing  Profile image

class About extends Component {
    render() {
        return (
            <>
                <div className='view-phone'>
                    <div className='flex-component'>
                        <div className='about-photo'>
                            <img src={`https://lh3.googleusercontent.com/d/1c864cAyKVXBhKCmAGiYBnqp3D5ainz6d`} id='LiviuStoicaDJ' alt='Liviu Stoica DJ' />
                        </div>
                        <div className='text-about'>
                            <p>Salut! Eu sunt Liviu. Povestea mea de DJ si MC a inceput undeva in 2005, dintr-un accident placut as putea spune, cand testam cateva echipamente noi intr-un pub. De atunci, stilul unic care are la baza elementul de surpriza a fost purtat prin pub-uri si cluburi nationale. Mixand funk, rock, pop, electro dar si muzica romaneasca veche am atras cu usurinta atentia unui public specific orientat catre experiente noi si senzatii muzicale. O experienta OPEN FORMAT plina de “soc si groaza”, in sensul bun al cuvantului! Mi-am creat un concept party denumit "The BlackOut" (initial BlackOut Friday cu Liviu) si il plimb prin locatiile din Romania, dar si peste hotare.</p>
                            <p>In prezent fac turnee in tara prin pub-uri, cluburi sau festivaluri, avand "elementul surprizei" ca trademark si misiune! Particip cu mare drag si la petreceri private. Am facut o serie de nunti sau botezuri atipice, cat si petreceri corporate sau mini revelioane care s-au dorit a fi altfel decat cele clasice. Iesi din zona de confort! Solicita o oferta!</p>
                            <p className='title-about size '>They call me</p>
                            <p className='title-about bold'>THE PARTY MACHINE</p>
                            <p className='title-about size '>and I don't plan to stop any time soon!</p>
                            <p className='title-about bold'> Let’s do this! </p>
                        </div>
                    </div>
                </div>
                <div className=' view-desktop'>
                    <div className='centered'>
                        <div className='flex-component'>
                            <div className='about-photo'>
                            <div style={{marginBottom:"30px"}} />
                                <img src={`https://lh3.googleusercontent.com/d/1c864cAyKVXBhKCmAGiYBnqp3D5ainz6d`} id='LiviuStoicaDJ' alt='Liviu Stoica DJ' />
                            </div>
                        </div>
                        <div className='flex-component'>
                            <div className='text-about'>
                                <p className='title-about-2'>Hola!</p>
                                <p>Eu sunt Liviu. Povestea mea de DJ si MC a inceput undeva in 2005, dintr-un accident placut as putea spune, cand testam cateva echipamente noi intr-un pub. De atunci, stilul unic care are la baza elementul de surpriza a fost purtat prin pub-uri si cluburi nationale. Mixand funk, rock, pop, electro dar si muzica romaneasca veche am atras cu usurinta atentia unui public specific orientat catre experiente noi si senzatii muzicale. O experienta OPEN FORMAT plina de “soc si groaza”, in sensul bun al cuvantului! Mi-am creat un concept party denumit "The BlackOut" (initial BlackOut Friday cu Liviu) si il plimb prin locatiile din Romania, dar si peste hotare.</p>
                                <p>In prezent fac turnee in tara prin pub-uri, cluburi sau festivaluri, avand "elementul surprizei" ca trademark si misiune! Particip cu mare drag si la petreceri private. Am facut o serie de nunti sau botezuri atipice, cat si petreceri corporate sau mini revelioane care s-au dorit a fi altfel decat cele clasice. Iesi din zona de confort! Solicita o oferta!</p>
                            
                                <p className='title-about' >They call me <span className=' title-p bold'>THE PARTY MACHINE</span> and I don't plan to stop any time soon! <span className='title-p bold'>Let’s do this!</span> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='view-tablet'>
                    <div className='flex-component'>
                        <div className='about-photo'>
                            <img src={`https://lh3.googleusercontent.com/d/1c864cAyKVXBhKCmAGiYBnqp3D5ainz6d`} id='LiviuStoicaDJ' alt='Liviu Stoica DJ' />
                        </div>
                        <div className='text-about'>
                            <p>Salut! Eu sunt Liviu. Povestea mea de DJ si MC a inceput undeva in 2005, dintr-un accident placut as putea spune, cand testam cateva echipamente noi intr-un pub. De atunci, stilul unic care are la baza elementul de surpriza a fost purtat prin pub-uri si cluburi nationale. Mixand funk, rock, pop, electro dar si muzica romaneasca veche am atras cu usurinta atentia unui public specific orientat catre experiente noi si senzatii muzicale. O experienta OPEN FORMAT plina de “soc si groaza”, in sensul bun al cuvantului! Mi-am creat un concept party denumit "The BlackOut" (initial BlackOut Friday cu Liviu) si il plimb prin locatiile din Romania, dar si peste hotare.</p>
                            <p>In prezent fac turnee in tara prin pub-uri, cluburi sau festivaluri, avand "elementul surprizei" ca trademark si misiune! Particip cu mare drag si la petreceri private. Am facut o serie de nunti sau botezuri atipice, cat si petreceri corporate sau mini revelioane care s-au dorit a fi altfel decat cele clasice. Iesi din zona de confort! Solicita o oferta!</p>
                            <div className='title-about size space '>They call me</div>
                            <div className='title-about bold space '>THE PARTY MACHINE</div>
                            <div className='title-about size space '>and I don't plan to stop any time soon!</div>
                            <div className='title-about bold space '> Let’s do this! </div>
                        </div>
                        
                    </div>
                </div>
            </>
        )
    }
}

export default About