import React, { Component } from 'react';
import axios from 'axios';



import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { BACK } from "./constant";

class PastEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventIds: [],
            eventIdTwo: []
        };
    }

    componentDidMount() {
        axios.get(`${BACK}/api/past-event`)
            .then(response => {
                this.setState({ eventIds: response.data });

            })
            .catch(error => {
                console.log(error);
            });
        axios.get(`${BACK}/api/past-event/16`)
            .then(response => {
                this.setState({ eventIdTwo: response.data })
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { eventIds, eventIdTwo } = this.state
        return (
            <div >
                <div className='title' >Evenimente Anterioare</div>
                <div className='isPhone'>(Swipe left to see more) </div>
                <br />
                <div style={{ position: 'relative' }}>
                    <Box className='media-event'
                        sx={{
                            display: 'flex',
                            gap: 1,
                            py: 0,
                            overflow: 'auto',
                            scrollSnapType: 'x mandatory',
                            '& > *': {
                                scrollSnapAlign: 'center',
                            },
                            '::-webkit-scrollbar': { display: 'flex' },
                        }}
                    >
                        {eventIds.map((eventId) => (
                            <Card
                                orientation="horizontal"
                                key={`${eventId.title}`}
                                sx={{
                                    '--Card-padding': (theme) => theme.spacing(0),
                                }}
                            >
                                <AspectRatio ratio='9/16' objectFit="scale-down" sx={{ minWidth: 200 }}>
                                    <img
                                        src={`https://lh3.googleusercontent.com/d/${eventId.image}`}
                                        alt={`eventId.title`}
                                    />
                                </AspectRatio>
                            </Card>
                        ))}
                    </Box>
                    <div className="isDesktop" style={{ position: 'center', top: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'space-around' }}>
                        <KeyboardArrowLeftIcon
                            onClick={() => {
                                const container = document.querySelector('.media-event');
                                container.scrollBy({
                                    left: -container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                        <div style={{ position: "center", color: "white", fontFamily: 'Norton' }} > To browse left/right use the arrows </div>
                        <KeyboardArrowRightIcon
                            onClick={() => {
                                const container = document.querySelector('.media-event');
                                container.scrollBy({
                                    left: container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    <Box className='media-event-2'
                        sx={{
                            display: 'flex',
                            gap: 1,
                            py: 0,
                            overflow: 'auto',
                            marginTop: '3vh',
                            scrollSnapType: 'x mandatory',
                            '& > *': {
                                scrollSnapAlign: 'center',
                            },
                            '::-webkit-scrollbar': { display: 'flex' },
                        }}
                    >
                        {eventIdTwo.map((eventIdT) => (
                            <Card
                                orientation="horizontal"
                                key={`${eventIdT.title}`}
                                sx={{
                                    '--Card-padding': (theme) => theme.spacing(0),
                                }}
                            >
                                <AspectRatio ratio='16/9' objectFit="scale-down" sx={{ minWidth: 200 }}>
                                    <img
                                        src={`https://lh3.googleusercontent.com/d/${eventIdT.image}`}
                                        alt={`eventIdT.title`}
                                        style={{}}
                                    />
                                </AspectRatio>
                            </Card>
                        ))}
                    </Box>
                    <div className="isDesktop" style={{ position: 'center', top: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'space-around' }}>
                        <KeyboardArrowLeftIcon
                            onClick={() => {
                                const container = document.querySelector('.media-event-2');
                                container.scrollBy({
                                    left: -container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                        <div style={{ position: "center", color: "white", fontFamily: 'Norton' }} > To browse left/right use the arrows </div>
                        <KeyboardArrowRightIcon
                            onClick={() => {
                                const container = document.querySelector('.media-event-2');
                                container.scrollBy({
                                    left: container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                    </div>
                </div>



            </div>
        );
    }
}

export default PastEvents;

