import React, { Component } from 'react';
import axios from 'axios';

import YouTube from 'react-youtube';


import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { BACK } from './constant';

class VideoGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoIds: []
        };
    }

    componentDidMount() {
        axios.get(`${BACK}/api/videos`)
            .then(response => {
                this.setState({ videoIds: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { videoIds } = this.state
        const opts = {
            height: '205',
            width: '360',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };
        return (
            <div style={{ position: 'relative' }}>
                <div className='title'>Galerie Video</div>
                <div className='isPhone'>(Swipe left to see more)</div>
                <br />
                <div style={{ position: 'relative' }}>
                    <Box className='media'
                        sx={{
                            display: 'flex',
                            gap: 1,
                            py: 0,
                            overflow: 'auto',
                            scrollSnapType: 'x mandatory',
                            '& > *': {
                                scrollSnapAlign: 'center',
                            },
                            '::-webkit-scrollbar': { display: 'flex' },
                        }}
                    >
                        {videoIds.map((videoId) => (
                            <Card
                                orientation='horizontal'
                                key={`${videoId.id}`}
                                sx={{
                                    '--Card-padding': (theme) => theme.spacing(0),
                                }}
                            >
                                <AspectRatio sx={{ minWidth: 290 }}>
                                    <YouTube videoId={`${videoId.id_video}`} opts={opts} onReady={this._onReady} />
                                </AspectRatio>
                            </Card>
                        ))}
                    </Box>
                    <div className="isDesktop" style={{ position: 'center', top: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'space-around' }}>
                        <KeyboardArrowLeftIcon
                            onClick={() => {
                                const container = document.querySelector('.media');
                                container.scrollBy({
                                    left: -container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                        <div style={{ position: "center", color: "white", fontFamily: 'Norton' }} > To browse left/right use the arrows </div>
                        <KeyboardArrowRightIcon
                            onClick={() => {
                                const container = document.querySelector('.media');
                                container.scrollBy({
                                    left: container.offsetWidth,
                                    behavior: 'smooth',
                                });
                            }}
                            sx={{ fontSize: 40, alignSelf: 'center', color: 'white', cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
        );
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}

export default VideoGallery;
