import React, { Component } from 'react';
import './App.css'

import About from './components/about';
import Gallery from './components/gallery';
import BookNow from './components/book-now';
import NewEvents from './components/upcoming-events';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import MenuIcon from '@mui/icons-material/Menu';

import { Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core"

// Social Media

import { ReactComponent as YouTubeIcon } from './Assets/youtube.svg'
import { ReactComponent as FacebookIcon } from './Assets/facebook.svg'
import { ReactComponent as InstagramIcon } from './Assets/instagram.svg'
import { ReactComponent as TikTokIcon } from './Assets/tiktok.svg'
import { ReactComponent as MixcloudIcon } from './Assets/mixcloud.svg'

import logo from './Assets/logo.png'
import Friends from './components/friends';
import Footer from './components/footer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.section1Ref = React.createRef();
    this.section2Ref = React.createRef();
    this.section3Ref = React.createRef();
    this.section4Ref = React.createRef();
    this.topButtonRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const section1 = this.section1Ref.current;
    const section2 = this.section2Ref.current;
    const section3 = this.section3Ref.current;
    const section4 = this.section4Ref.current;

    const sections = [section1, section2, section3, section4];
    const sectionTops = sections.map(section => section.getBoundingClientRect().top);
    const activeIndex = sectionTops.findIndex(top => top >= 0);
    this.setState({ activeSection: activeIndex });

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const showScrollToTop = scrollTop > 0;
    this.setState({ showScrollToTop });

  };

  scrollToSection = (index) => {

    const section = this[`section${index + 1}Ref`].current;
    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: sectionTop - 130, behavior: 'smooth' });
    // section.scrollIntoView({ top: section - 500, behavior: 'smooth' });
    this.setState({ showMenu: false })
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleOpenInstagram = () => {
    window.open("https://www.instagram.com/liviustoicadj/")
  };
  handleOpenFacebook = () => {
    window.open("https://www.facebook.com/liviustoicadj");
  };
  handleOpenYoutube = () => {
    window.open("https://www.youtube.com/@DeBunSimtTV")
  };
  handleOpenTiktok = () => {
    window.open("https://www.tiktok.com/@liviustoicadj")
  };
  handleOpenMixcloud = () => {
    window.open("https://www.mixcloud.com/liviu-stoica-dj/")
  };
  handleWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=40744558784")
  }


  render() {
    return (
      <>
        <div className="App ">
          <nav >
            <img src={logo} alt="Logo" className="logo" onClick={this.scrollToTop} />
            <div className='bookNow'>
              <button className='button-font' onClick={() => this.scrollToSection(2)}>
                Book Now
              </button>
            </div>
            <div className='right-menu'>
              <IconButton className='menu' onClick={this.handleDrawerOpen}>
                <MenuIcon style={{ color: 'white' }} />
              </IconButton>
              <Drawer anchor='right' open={this.state.open} onClose={this.handleDrawerClose} className='drawer-opacity' style={{ background: 'black' }}>
                <div className='drawer' style={{ width: 250 }}>
                  <List className='list-position'>
                    <IconButton button onClick={this.handleDrawerClose}>
                      <button className='button-font-menu'> X </button>
                    </IconButton>
                    <ListItem className='button-font' button>
                      <ListItemText className='button-font' onClick={() => { this.scrollToTop(); this.handleDrawerClose() }}  > <button className='button-font-menu'> Despre </button> </ListItemText>
                    </ListItem>
                    <ListItem button>
                      <ListItemText className='button-font' onClick={() => { this.scrollToSection(1); this.handleDrawerClose() }}  > <button className='button-font-menu'> Media </button> </ListItemText>
                    </ListItem>
                    <ListItem button>
                      <ListItemText className='button-font' onClick={() => { this.scrollToSection(2); this.handleDrawerClose() }} > <button className='button-font-menu'> Book Now </button> </ListItemText>
                    </ListItem>
                    <ListItem className='button-font' button>
                      <ListItemText onClick={() => { this.scrollToSection(3); this.handleDrawerClose() }}><button className='button-font-menu'> Events </button> </ListItemText>
                    </ListItem>
                    <ListItem>
                      <div className='column'>
                        <IconButton onClick={this.handleOpenInstagram}> <InstagramIcon className='icon' /> </IconButton>
                        <IconButton onClick={this.handleOpenFacebook}> <FacebookIcon className='icon' /> </IconButton>
                        <IconButton onClick={this.handleOpenTiktok}> <TikTokIcon className='icon' /> </IconButton>
                        <IconButton onClick={this.handleOpenYoutube}> <YouTubeIcon className='icon' /> </IconButton>
                        <IconButton onClick={this.handleOpenMixcloud}> <MixcloudIcon className='icon' /> </IconButton>
                      </div>
                    </ListItem>

                    <ListItem>
                      <div className='bottom'>
                        <button onClick={this.handleWhatsApp} className='whatsapp' > <h1 className='whatsapp'>Contact me via Whatsapp</h1> </button>
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Drawer>
            </div>
            <div className='right'>
              <ul >
                <li>
                  <button className='button-font' onClick={this.scrollToTop}>
                    Despre
                  </button>
                </li>
                <li>
                  <button className='button-font' onClick={() => this.scrollToSection(1)}>
                    Media
                  </button>
                </li>
                <li>
                  <button className='button-font' onClick={() => this.scrollToSection(2)}>
                    Book Now
                  </button>
                </li>
                <li>
                  <button className='button-font' onClick={() => this.scrollToSection(3)}>
                    Events
                  </button>
                </li>
              </ul>
            </div>
          </nav>
          <div style={{ marginTop: '10%' }} >
            <section style={{ marginTop: '10%' }} ref={this.section1Ref} >
              <div> <About /> </div>
            </section>
            <section style={{ marginTop: '20%' }} ref={this.section2Ref} >
              <div > <Gallery /> </div>
            </section>
            <section style={{ marginTop: '20vh' }} ref={this.section3Ref} >
              <div> <BookNow /> </div>
            </section>
            <section style={{ marginTop: '20vh' }} ref={this.section4Ref} >
              <div height='100vh' > <NewEvents /> </div>
            </section>
            <section style={{ marginTop: '20vh' }}  >
              <div height="80vh"> <Friends /> </div>
            </section>
          </div>
          <ArrowCircleUpIcon fontSize='large' className='top-button' ref={this.topButtonRef} onClick={this.scrollToTop} />
        </div>
        <Footer/>
        <iframe  alt='Ich bin aus Rumemia' title='Liviu Stoica DJ - Ich bin aus Rumemia!' className='react-player' width="100%" height="60px" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fliviu-stoica-dj%2Fleeview-stoica-ich-bin-aus-rum%C3%A4nien%2F" ></iframe>

      </>
    );
  }
}

export default App;
