import React, { Component } from 'react'

import axios from 'axios'

import { BACK } from './constant';

export class NewEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newEventsIds: []
        };
    }

    componentDidMount() {
        axios.get(`${BACK}/api/new-event`)
            .then(response => {
                this.setState({ newEventsIds: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { newEventsIds } = this.state
        return (
            <div className="upcoming-center">
                <div className='upcoming-title'>Evenimente Viitoare</div>
                <div className='upcoming-flex'>
                    {newEventsIds.length === 0 ? (
                        <div className='upcoming-not-found' >
                            <div>To be announced.</div>
                            <div>Stay Tuned!</div>
                        </div>
                    ) : (
                        <div className='upcoming-flex'>
                            {newEventsIds.map((newEventsId) => (
                                <div className='upcoming-div' alt={`"${newEventsId.event_name}` } >
                                    <img alt={`${newEventsId.title}`} src={`https://lh3.googleusercontent.com/d/${newEventsId.image}`} className='upcoming-div-small' />
                                    <div className='upcoming-div-row' >
                                        <div className='upcoming-div-name'>  {newEventsId.location}   </div>
                                        <div className='upcoming-div-date'>{newEventsId.event_date}</div>
                                    </div>
                                    <div className='upcoming-div-location'> <button onClick={()=> {window.open(`${newEventsId.site}`,'_blank')} } className="upcoming-button"> {newEventsId.event_name} </button> </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default NewEvents

